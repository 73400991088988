var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outBox"},[_vm._m(0),_c('div',{staticClass:"why"},[_c('Mytitle',{attrs:{"data":{
        h: '为什么选择拓幻科技',
      }}}),_vm._m(1)],1),_c('div',{staticClass:"weal"},[_c('Mytitle',{attrs:{"data":{
        h: '薪酬与福利',
        p: '极具竞争力对薪酬与福利，是你追梦路上对坚强后盾，为你扫除后顾之忧',
      }}}),_vm._m(2)],1),_c('div',{staticClass:"post"},[_c('Mytitle',{attrs:{"data":{
        h: '职位列表',
        p: '请发送简历至tillusory@163.com，命名方式为岗位+姓名',
      }}}),_c('div',{staticClass:"post_conter"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("职位类别")]),_c('ul',_vm._l((_vm.left),function(item,index){return _c('li',{key:index,class:index == _vm.select ? 'sel' : '',on:{"click":function($event){return _vm.changRight(index)}}},[_c('span',[_vm._v(_vm._s(item))])])}),0)]),_c('div',{staticClass:"right"},[_c('el-table',{ref:"table",staticClass:"table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"row-style":{ height: '54px' },"height":"588","align":"center"},on:{"row-click":_vm.clickRowHandle}},[_c('el-table-column',{attrs:{"prop":"name","label":"职位名称","width":"352"}}),_c('el-table-column',{attrs:{"prop":"type","label":"职位类别","width":"233"}}),_c('el-table-column',{attrs:{"prop":"address","label":"工作地点","width":"134"}}),_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('h3',{staticClass:"ex_h3"},[_vm._v("岗位职责")]),_c('ul',{staticClass:"ex_ul"},_vm._l((props.row.duct.duct),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(index + 1 + ".")+_vm._s(item)+" ")])}),0),_c('h3',{staticClass:"ex_h3"},[_vm._v("岗位要求")]),_c('ul',{staticClass:"ex_ul"},_vm._l((props.row.duct.claim),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(index + 1 + ".")+_vm._s(item)+" ")])}),0)]}}])})],1),_c('el-pagination',{attrs:{"layout":"prev, pager, next","total":_vm.right.length,"align":"center","current-page":_vm.currentPage},on:{"current-change":_vm.currentChange}})],1)])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"top_text"},[_c('h1',[_vm._v("迎接成长，共赢未来")]),_c('p',[_vm._v("Join Us")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"why_conter"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"imgBox"},[_c('img',{attrs:{"src":require("@/assets/home/join/加入我们_p1.png"),"alt":""}})]),_c('div',{staticClass:"item_div"},[_c('h3',[_vm._v("优秀团队 信任包容")]),_c('p',[_vm._v(" 团队成员来自国外留学博士硕士和国内985高校，责任共担，有问题积极真诚面对，共同解决。在这里，你遇到的不只是同事，更是一起成长的伙伴。 ")])]),_c('div',{staticClass:"imgBox"},[_c('img',{attrs:{"src":require("@/assets/home/join/加入我们_p3.png"),"alt":""}})])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item_div"},[_c('h3',[_vm._v("长于专业 行业领导")]),_c('p',[_vm._v(" 拓幻科技长期处于智能美颜科技领域，提供稳定、高效、性价比极高的智能美颜解决方案和服务，持续为客户创造价值。致力于成为新一代科技型互联网企业领航者。 ")])]),_c('div',{staticClass:"imgBox"},[_c('img',{attrs:{"src":require("@/assets/home/join/加入我们_p2.png"),"alt":""}})]),_c('div',{staticClass:"item_div"},[_c('h3',[_vm._v("迎接成长 共赢未来")]),_c('p',[_vm._v(" 舒适的办公环境，具备竞争力的薪资、完善的激励体系以及人性化的员工福利，让你没有后顾之忧的尽心拼搏。公司持续开展股权激励，邀请志同道合的你一起分享公司成果。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weal_conter"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/home/join/icon_join_wulian@2x.png"),"alt":""}}),_c('h3',[_vm._v("五险一金")]),_c('p',[_vm._v("为了你的前途，我们在保障上努力争先，让你无后顾之忧")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/home/join/icon_join_holiday@2x.png"),"alt":""}}),_c('h3',[_vm._v("带薪年假")]),_c('p',[_vm._v("光有年假还不够，我们还是带薪的，拿着工资来一场说走就走的旅行吧")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/home/join/icon_join_tuanjian@2x.png"),"alt":""}}),_c('h3',[_vm._v("团建活动")]),_c('p',[_vm._v("加入拓幻智能团队，我们就是一家人，一起欢聚一起乐")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/home/join/icon_join_jieri@2x.png"),"alt":""}}),_c('h3',[_vm._v("节庆关怀")]),_c('p',[_vm._v(" 逢年过节，拓幻智能会精心准备节日礼物，一起欢快的度过每一个节假日 ")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/home/join/icon_join_butie@2x.png"),"alt":""}}),_c('h3',[_vm._v("加班补贴")]),_c('p',[_vm._v("让你的每一份辛勤付出，都会获得汇报")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/home/join/icon_join_jinsheng@2x.png"),"alt":""}}),_c('h3',[_vm._v("晋升空间")]),_c('p',[_vm._v("拓幻智能制定了明确的岗位晋升标准，晋升空间极大")])])])
}]

export { render, staticRenderFns }
<template>
  <div class="outBox">
    <!-- 大图 -->
    <div class="top">
      <div class="top_text">
        <h1>迎接成长，共赢未来</h1>
        <p>Join Us</p>
      </div>
    </div>
    <!-- 选择拓幻 -->
    <div class="why">
      <Mytitle
        :data="{
          h: '为什么选择拓幻科技',
        }"
      ></Mytitle>
      <div class="why_conter">
        <div class="item">
          <div class="imgBox">
            <img src="@/assets/home/join/加入我们_p1.png" alt="" />
          </div>
          <div class="item_div">
            <h3>优秀团队 信任包容</h3>
            <p>
              团队成员来自国外留学博士硕士和国内985高校，责任共担，有问题积极真诚面对，共同解决。在这里，你遇到的不只是同事，更是一起成长的伙伴。
            </p>
          </div>
          <div class="imgBox">
            <img src="@/assets/home/join/加入我们_p3.png" alt="" />
          </div>
        </div>
        <div class="item">
          <div class="item_div">
            <h3>长于专业 行业领导</h3>
            <p>
              拓幻科技长期处于智能美颜科技领域，提供稳定、高效、性价比极高的智能美颜解决方案和服务，持续为客户创造价值。致力于成为新一代科技型互联网企业领航者。
            </p>
          </div>
          <div class="imgBox">
            <img src="@/assets/home/join/加入我们_p2.png" alt="" />
          </div>
          <div class="item_div">
            <h3>迎接成长 共赢未来</h3>
            <p>
              舒适的办公环境，具备竞争力的薪资、完善的激励体系以及人性化的员工福利，让你没有后顾之忧的尽心拼搏。公司持续开展股权激励，邀请志同道合的你一起分享公司成果。
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 福利 -->
    <div class="weal">
      <Mytitle
        :data="{
          h: '薪酬与福利',
          p: '极具竞争力对薪酬与福利，是你追梦路上对坚强后盾，为你扫除后顾之忧',
        }"
      ></Mytitle>

      <div class="weal_conter">
        <div class="item">
          <img src="@/assets/home/join/icon_join_wulian@2x.png" alt="" />
          <h3>五险一金</h3>
          <p>为了你的前途，我们在保障上努力争先，让你无后顾之忧</p>
        </div>
        <div class="item">
          <img src="@/assets/home/join/icon_join_holiday@2x.png" alt="" />
          <h3>带薪年假</h3>
          <p>光有年假还不够，我们还是带薪的，拿着工资来一场说走就走的旅行吧</p>
        </div>
        <div class="item">
          <img src="@/assets/home/join/icon_join_tuanjian@2x.png" alt="" />
          <h3>团建活动</h3>
          <p>加入拓幻智能团队，我们就是一家人，一起欢聚一起乐</p>
        </div>
        <div class="item">
          <img src="@/assets/home/join/icon_join_jieri@2x.png" alt="" />
          <h3>节庆关怀</h3>
          <p>
            逢年过节，拓幻智能会精心准备节日礼物，一起欢快的度过每一个节假日
          </p>
        </div>
        <div class="item">
          <img src="@/assets/home/join/icon_join_butie@2x.png" alt="" />
          <h3>加班补贴</h3>
          <p>让你的每一份辛勤付出，都会获得汇报</p>
        </div>
        <div class="item">
          <img src="@/assets/home/join/icon_join_jinsheng@2x.png" alt="" />
          <h3>晋升空间</h3>
          <p>拓幻智能制定了明确的岗位晋升标准，晋升空间极大</p>
        </div>
      </div>
    </div>
    <!-- 职位 -->
    <div class="post">
      <Mytitle
        :data="{
          h: '职位列表',
          p: '请发送简历至tillusory@163.com，命名方式为岗位+姓名',
        }"
      ></Mytitle>
      <div class="post_conter">
        <div class="left">
          <h3>职位类别</h3>
          <ul>
            <li
              :class="index == select ? 'sel' : ''"
              v-for="(item, index) in left"
              :key="index"
              @click="changRight(index)"
            >
              <span>{{ item }}</span>
            </li>
          </ul>
        </div>
        <div class="right">
          <el-table
            ref="table"
            :data="tableData"
            class="table"
            style="width: 100%"
            :row-style="{ height: '54px' }"
            height="588"
            align="center"
            @row-click="clickRowHandle"
          >
            <el-table-column prop="name" label="职位名称" width="352">
            </el-table-column>
            <el-table-column prop="type" label="职位类别" width="233">
            </el-table-column>
            <el-table-column prop="address" label="工作地点" width="134">
            </el-table-column>
            <!-- 展开 -->
            <el-table-column type="expand">
              <template slot-scope="props">
                <h3 class="ex_h3">岗位职责</h3>
                <ul class="ex_ul">
                  <li v-for="(item, index) in props.row.duct.duct" :key="index">
                    {{ index + 1 + "." }}{{ item }}
                  </li>
                </ul>
                <h3 class="ex_h3">岗位要求</h3>
                <ul class="ex_ul">
                  <li
                    v-for="(item, index) in props.row.duct.claim"
                    :key="index"
                  >
                    {{ index + 1 + "." }}{{ item }}
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            layout="prev, pager, next"
            :total="right.length"
            align="center"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Mytitle from "@/components/Mytitle/index"; //标题
export default {
  components: { Mytitle },
  data() {
    return {
      // 职位列表数据
      left: ["全部", "技术", "设计", "市场", "智能"],
      postData: [
        {
          id: 1,
          name: "计算机图形高级算法工程师",
          type: "技术",
          address: "南京",
          duct: {
            duct: [
              "研究与开发计算机图形学算法在人体、人脸动作捕获和3D Avatar渲染、3D AR、3D动画方面的应用，如Blendshape、faceshift, Facerig等",
            ],
            claim: [
              "扎实的计算机图形学和数学基础",
              "熟悉C/C++编程，可以在Linux、Windows等多个平台工作, 熟悉OpenGL、OpenGL ES、Shader等常用技术",
              "本专业领域硕士以上学历或者本科具有1年以上相关工作经验",
              "有计算机视觉、机器学习基础者",
              "有手机端开发经验者优先",
            ],
          },
        },
        {
          id: 1,
          name: "3D图像算法工程师",
          type: "技术",
          address: "南京",
          duct: {
            duct: [
              "基于深度相机（如结构光、TOF、双目相机）获取的3D点云数据，开发与点云深度图像处理的相关算法：如点云图像去燥、深度数据补全、深度图像三维重建、目标识别、位姿估计、三维人脸、身体姿态动作捕获等",
            ],
            claim: [
              "具有计算机/电子工程/自动化等相关学科本科及以上学历",
              "具有3D图像处理的相关经验",
              "有较强的C/C++，python/matlab编程能力",
              "熟练使用如PCL/VCGLIB/OpenCV等算法库",
              "有较强的英文听说读写能力",
            ],
          },
        },
        {
          id: 1,
          name: "深度学习平台算法工程师",
          type: "技术",
          address: "南京",
          duct: {
            duct: [
              "研究与实现前沿深度学习技术",
              "搭建深度学习框架和训练流程",
              "基于深度学习开发其在图像/视频模式识别的应用",
            ],
            claim: [
              "硕士及以上学历",
              "至少有一项以下方向的学习和开发经验：机器学习（如CNN/RNN、Boosting、Random Forests等），计算机视觉（如图像识别理解，人脸检测识别、目标检测和跟踪、OCR、增强现实、图像质量评价，图像分割等），有过深入的工作与研究",
              "具有较强的计算机编程实践能力，掌握C++/MATLAB/Python其中一种语言",
              "具有熟练阅读英文文献的能力；能够跟踪学术界和工业界的最新研究成果",
            ],
          },
        },
        {
          id: 1,
          name: "计算机视觉工程师",
          type: "技术",
          address: "南京",
          duct: {
            duct: [
              "负责计算机视觉算法的研发（人脸检测、跟踪、识别，图像分割、识别，3D视觉, 三维重建，人体姿态与手势跟踪，目标跟踪，图像复原、增强等熟悉一类即可",
              "把开发的算法应用到各种有挑战性的现实场景中去，如手机和其它嵌入式设备",
            ],
            claim: [
              "本科及以上学历，有扎实的计算机视觉和机器学习基础",
              "掌握C/C++和MATLAB/Python，有很强的算法实现能力",
              "熟练使用OpenCV等算法库",
              "有深度学习相关科研和工作经历者优先",
              "良好的英文交流能力",
              "发表过视觉和AI领域顶级会议的优先",
            ],
          },
        },
        {
          id: 2,
          name: "3D模型设计师",
          type: "设计",
          address: "南京",
          duct: {
            duct: [
              "把握方案设计理念，把握整体设计风格，独立完成效果图设计",
              "与平面设计师进行充分沟通，协助完成深化设计",
              "负责方案设计的效果图及后期制作（效果图、实景效果）",
            ],
            claim: [
              "大学本科以上学历，3年以上活动行业从业经验，美术或设计相关专业毕业，具有对平面作品3D渲染的设计经验",
              "具有三维空间创意能力、综合设计能力强，并富有创造力，具有成熟的3D设计作品以及成功3D设计案例熟练操作3Dmax及周边相关的渲染软件",
              "具有良好的人际沟通和表达能力，理解并重视团队合作，对工作认真负责",
              "配合客户要求，能承受阶段时间内工作压力",
            ],
          },
        },
        {
          id: 2,
          name: "视觉交互设计师",
          type: "设计",
          address: "南京",
          duct: {
            duct: [
              "完成产品的交互、UI、用研、品牌等设计工作",
              "主要负责产品的视觉创意，协助产品完成项目前期的创意提案，把控整体平面设计风格与出品质量",
            ],
            claim: [
              "具有良好的创意构思能力，扎实的美术功底及设计能力，构图及色彩技艺，美术专业生优先",
              "对视觉设计、色彩搭配有很好的把握，具有良好的创意能力，能有效的将想法转化为设计方案",
              "能承受高强度的工作压力，执行力强，有大局观，具备良好的团队合作精神有一定的产品思维",
            ],
          },
        },
        {
          id: 3,
          name: "新媒体大客户经理",
          type: "市场",
          address: "南京",
          duct: {
            duct: [
              "负责新媒体行业大客户市场开拓",
              "建立并拓展相关行业内比较标杆典型的客户",
              "开拓广电，传媒，文化体育等行业领域资源为主",
            ],
            claim: [
              "具有3年以上广电新媒体行业销售经验，5年以上销售经验",
              "具有百万级以上项目操作经验",
              "拥有省级广电新媒体、IPTV等客户资源、拥有（东方明珠新媒体、南方新媒体、湖南广电、CNTV、国广东方、央广新媒体和华数传媒）的客户资源的优先",
              "具备云计算、大数据、移动互联网、物联网、人工智能等相关经验者优先",
            ],
          },
        },
        {
          id: 3,
          name: "产品营销分析师",
          type: "市场",
          address: "南京",
          duct: {
            duct: [
              "负责拓幻科技品牌营销中，Web和H5相关产品的功能策划和实施",
              "负责公司官网，博客，活动app等产品的设计，优化",
              "对所负责的产品进行日常维护及数据跟踪，并根据数据及反馈持续优化",
            ],
            claim: [
              "两年以上产品经理经验",
              "参与产品功能需求、设计、研发及维护改进的整个过程",
              "良好的沟通能力、需求分析能力，有想法，有创意，脑洞要开",
              "自我驱动能力及抗压能力强",
              "有CMS系统经验者优先",
              "有APP产品策划经验者优先",
            ],
          },
        },
        {
          id: 3,
          name: "文案策划",
          type: "市场",
          address: "南京",
          duct: {
            duct: [
              "根据拓幻科技品牌推广计划，能独立完成创意策略，策划阶段性选题",
              "能根据公司不同项目，以及不同的媒体投放渠道，策划撰写相应的图文、软文、新闻稿等",
              "结合社会热点，撰写传播方案并组织实施",
              "跟踪并反馈各类媒体发布的情况，进行梳理总结",
            ],
            claim: [
              "2年以上互联网内容创作经验",
              "热爱技术，思维活跃，对互联网市场的敏感度高，具备较强的文案能力",
              "具有新颖视角，具有较强的创意概念发想能力和开发的想象力",
              "较强的沟通能力和执行能力",
            ],
          },
        },
        {
          id: 3,
          name: "售前工程师",
          type: "市场",
          address: "南京",
          duct: {
            duct: [
              "配合销售跟客户沟通，收集客户业务信息、应用场景信息，整理出有效需求，分解成功能需求列表",
              "将功能需求列表转化为整体解决方案交付给客户，协调其它部门资源，推进实施方案",
              "配合客户对接、测试，指导客户接入流程和细节",
              "与客户保持技术上的沟通，及时解答疑问、响应新需求、处理突发故障",
              "协助销售人员，提升客户满意度，提升公司整体销售业绩",
              "编写行业解决方案文档、产品对外文档，定期维护更新，追随云存储、云服务行业的趋势",
            ],
            claim: [
              "优秀的沟通表达和协调能力，系统的思考能力，良好的服务意识",
              "IT服务领域1年以上开发、售前工程师、或技术支持相关岗位工作经验",
              "熟悉IOS/Android系统，熟悉http/tcp协议",
              "能熟悉使用各种办公软件撰写文档和解决方案",
              "有Java/C++/Android/IOS/C/Ruby等编程语言开发工作经验优先",
            ],
          },
        },
        {
          id: 4,
          name: "组织发展经理",
          type: "职能",
          address: "南京",
          duct: {
            duct: [
              "根据公司的发展需要，完成绩效和目标体系的优化",
              "负责制定组织架构、人员编制及部门职责管理的政策、流程，并指导实施",
              "推动任职资格体系的建设和落地，建立员工职涯发展双通道",
              "完善后备人才培养体系，通过领导力提升计划促进各级员工的晋升和发展，搭建有效的人才梯队，满足公司业务发展的需求",
              "制定关键人才盘点和考核方案，制定关键人才盘点的工具方法，以及保留机制",
            ],
            claim: [
              "大学本科及以上学历，人力资源、管理类相关专业优先",
              "五年以上人力资源管理工作经验，三年以上相关互联网公司组织发展类管理工作经验，对组织管理、干部管理、员工发展工作经验丰富，外资咨询公司背景优先",
              "具有良好的沟通能力，团队建设及目标管理能力，优秀的逻辑分析能力和系统思维; 具备较强的责任心，推动力及创新力",
            ],
          },
        },
        {
          id: 4,
          name: "法务专员",
          type: "职能",
          address: "南京",
          duct: {
            duct: [
              "协助主管完成公司各类合同审核及法务相关工作",
              "协助主管进行公司合规性制度建设及内外部合规事件处理",
              "协助主管处理公司维权及侵权纠纷、用户投诉等法律案件",
              "协助主管进行公司知识产权管理相关工作，包括知识产权申请、维护等",
              "收集、整理各类与互联网相关政策、法规等信息，并协助主管进行专题法律问题研究",
              "协助主管完成各政府项目的准备、申报、审核跟进工作",
              "协助主管完成与政府相关部门的联络和对接工作，做好与政府相关行政机构的关系维护",
              "办理各项资质年检、续办、变更等日常事务",
              "完成上级领导安排的其他工作，及时处理好工作并做好工作汇报",
            ],
            claim: [
              "法律或相关专业，具有两年以上公司法务或律师事务所工作经验，有互联网公司合规工作经验，或有互联网相关专业背景者优先",
              "有较强的文字功底，具备撰写各类工作文件的能力和水平",
              "思维严谨、工作细致，有很强的工作责任心和执行力",
              "具备良好的沟通表达能力及团队协作精神",
            ],
          },
        },
      ],
      right: null, //右侧显示数据
      select: 0, //选中
      currentPage: 1, //当前页
    };
  },
  computed: {
    tableData: function () {
      let tableData = this.right.slice(
        (this.currentPage - 1) * 10,
        this.currentPage * 10
      );
      return tableData;
    },
  },
  created() {
    this.right = this.postData;
  },
  methods: {
    // 左边列表点击
    changRight(i) {
      this.select = i;
      if (i == 0) {
        this.right = this.postData;
      } else {
        let arr = this.postData.filter((r) => r.id == i);
        this.right = arr;
      }
    },
    // 分页点击
    currentChange(currentPage) {
      this.currentPage = currentPage;
    },
    // 点击行展开手风琴效果
    clickRowHandle(row) {
      let $table = this.$refs.table;
      this.tableData.map((item) => {
        if (row.name != item.name) {
          $table.toggleRowExpansion(item, false);
        }
      });
      $table.toggleRowExpansion(row);
      /* this.$refs.table.toggleRowExpansion(row); */
    },
  },
};
</script>
<style lang="scss" scoped>
$width: 79.1%;
.outBox {
  width: 100%;
  //背景大图部分
  .top {
    width: 100%;
    height: 500px;
    line-height: 400px;
    text-align: center;
    color: #fff;
    background: url("~@/assets/home/join/加入我们首图.png") no-repeat;
    background-size: 100% 500px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    .top_text {
      height: 197px;
      h1 {
        height: 65px;
        font-size: 44px;
        font-weight: 500;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 47px;
      }
    }
  }
  //  选择拓幻
  .why {
    height: 681px;
    .why_conter {
      width: $width;
      height: 480px;
      overflow: hidden;
      margin: auto;
      font-weight: 400;
      box-shadow: 0px 0px 18px 0px rgba(180, 180, 180, 0.46);
      .item {
        height: 50%;
        display: flex;
        text-align: center;
        align-items: center;
        .imgBox {
          width: 33.3%;
          height: 100%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            transition: all 1s;
          }
          img:hover {
            transform: scale(1.2);
          }
        }

        .item_div {
          width: 33.4%;
          h3 {
            font-size: 16px;
            color: #222222;
            line-height: 38px;
          }
          p {
            width: 349px;
            margin: auto;
            font-size: 14px;
            color: #999999;
          }
        }
      }
    }
  }
  // 福利
  .weal {
    width: 100%;
    height: 699px;
    background: #f5f5f5;
    padding-top: 1px;

    .weal_conter {
      width: $width;
      margin: auto;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      .item {
        width: 23%;
        height: 148px;
        padding: 30px 0;
        margin: 0 55px;
        background: rgba($color: #fff, $alpha: 0);
        transition: all 1s;
        img {
          width: 60px;
        }
        h3 {
          height: 40px;
          font-size: 16px;
          font-weight: 400;
          color: #222222;
          line-height: 40px;
        }
        p {
          width: 100%;
          margin: auto;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 24px;
          opacity: 0;
          transition: all 1s;
        }
      }
      .item:hover {
        background: rgba($color: #fff, $alpha: 1);
        box-shadow: 0px 0px 18px 0px rgba(180, 180, 180, 0.46);
        p {
          opacity: 1;
        }
      }
    }
  }
  // 职位
  .post {
    height: 976px;
    .post_conter {
      width: $width;
      margin: auto;
      display: flex;
      justify-content: center;
      .left {
        width: 250px;
        height: 352px;
        background: #ffffff;
        box-shadow: 0px 0px 18px 0px rgba(180, 180, 180, 0.46);
        margin-right: 40px;

        h3 {
          height: 79px;
          font-size: 16px;
          font-weight: 500;
          color: #222222;
          line-height: 79px;
          padding-left: 31px;
        }
        li {
          height: 50px;
          line-height: 50px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          cursor: pointer;
          span {
            display: inline-block;
            width: 88px;
            text-align: center;
          }
        }

        .sel {
          background: #f4f7fe;
          border-right: 2px solid #4c8cf5;
          color: #4c8cf5;
        }
        li:hover {
          color: #4c8cf5;
        }
      }
      .right {
        width: 770px;
        height: 676px;
        background: #ffffff;
        box-shadow: 0px 0px 18px 0px rgba(180, 180, 180, 0.46);
        padding: 32px;
        .el-table::before {
          width: 0;
        }
        .el-pagination {
          padding: 40px 5px;
        }
        .ex_h3 {
          height: 30px;
          font-size: 14px;
          font-weight: 500;
          line-height: 38px;
          color: #222;
        }
        .ex_ul {
          li {
            width: 700px;
            font-size: 14px;
            line-height: 24px;
          }
        }
        .img {
          width: 10px;
          height: 11px;
        }
      }
    }
  }
}
/* 屏幕小于 */
@media screen and (max-width: 1440px) {
  .outBox {
    width: 1440px;
  }
}
</style>